import SearchTerm from "./components/search-term";
import { ProjectFilterList, ProjectFilterSelect } from "./components/project-filter";
import { DashboardCard } from "./components/dashboard-card";
import SortBy from "./components/dashboards-sort-by";
import LikeFilter from "./components/like-filter";
import { useCurrentUser } from "~/features/current-user";
import { DashboardSearchData } from "~/services/discover-service";
import { Link, useLocation, useNavigate } from "@remix-run/react";
import { useDebouncedCallback } from "use-debounce";
import { Paging } from "./components/paging";
import { NoResults } from "./components/no-results";
import { $path } from "remix-routes";
import { Button } from "@fscrypto/ui";
import { tracking } from "~/utils/tracking";

interface DashboardsContainerProps {
  data: DashboardSearchData;
  basePath: string;
}

export const DashboardsContainer = ({ data, basePath }: DashboardsContainerProps) => {
  const { currentUser } = useCurrentUser();
  const navigate = useNavigate();
  const location = useLocation();
  const { page, pageSize, liked, project, projects, sortBy, totalResults, search, ownerProfile, items } = data;

  // Debounce callback
  const handleSearch = useDebouncedCallback(
    (searchTerm: string) => {
      if (basePath.includes("verified-insights")) {
        tracking("search_on_verified_insights_page", "Discover");
      } else {
        tracking("search_on_dashboards_page", "Discover");
      }
      const searchParams = new URLSearchParams(location.search);
      searchParams.delete("page");

      if (searchTerm === "") {
        searchParams.delete("search");
      } else {
        searchParams.set("search", searchTerm);
      }

      const newUrl = `${location.pathname}?${searchParams.toString()}`;

      navigate(newUrl);
    },
    // delay in ms
    800,
  );

  const handleProjectChange = (project: string) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete("page");
    if (project === "all") {
      navigate(`${basePath}?${searchParams.toString()}`);
    } else {
      if (ownerProfile) {
        navigate(`${basePath}/projects/${project}?${searchParams.toString()}`);
      } else {
        navigate(`${basePath}/${project}?${searchParams.toString()}`);
      }
    }
  };

  const handleSort = (sort: string) => {
    const searchParams = new URLSearchParams(location.search);
    if (sort === "") {
      searchParams.delete("sortBy");
    } else {
      searchParams.set("sortBy", sort);
    }

    const newUrl = `${location.pathname}?${searchParams.toString()}`;

    navigate(newUrl);
  };

  const handleLiked = (liked: boolean) => {
    const searchParams = new URLSearchParams(location.search);
    if (liked) {
      searchParams.set("liked", "true");
    } else {
      searchParams.delete("liked");
    }

    const newUrl = `${location.pathname}?${searchParams.toString()}`;

    navigate(newUrl);
  };

  const getPagedUrl = (page: number) => {
    const searchParams = new URLSearchParams(location.search);
    if (page) {
      searchParams.set("page", page.toString());
    } else {
      searchParams.delete("page");
    }

    return `${location.pathname}?${searchParams.toString()}`;
  };

  return (
    <main className="grid grid-cols-1 gap-10 md:grid-cols-4">
      <div className="col-span-3">
        <div className="flex flex-col space-y-3 pb-8 md:flex-row md:space-x-3 md:space-y-0">
          <SearchTerm onChange={handleSearch} value={search} />
          <Link to={$path("/rewards/top-dashboards")}>
            <Button
              size="md"
              variant="secondary"
              onClick={() => {
                tracking("click_top_dashboards_on_insights_dashboards_page", "Discover");
              }}
            >
              Top Dashboards
            </Button>
          </Link>
          <SortBy
            sortBy={sortBy ?? "trending"}
            onChange={(sort) => {
              const event = determineSortEvent(sort as DashboardSearchData["sortBy"]);
              tracking(event, "Discover");
              handleSort?.(sort);
            }}
          />
          <div className="md:hidden">
            <ProjectFilterSelect onChange={handleProjectChange} projects={projects} projectName={project} />
          </div>
          {currentUser && <LikeFilter likedByMe={liked ?? false} onSetLikedByMe={handleLiked} />}
        </div>

        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
          {!basePath.includes("verified-insights") && items.length === 0 && (
            <div className="col-span-4">
              <NoResults />
            </div>
          )}

          {basePath.includes("verified-insights") && getBonkDashboard()}

          {data.items.map((dashboard) => (
            <DashboardCard dashboard={dashboard} key={dashboard.id} showUser />
          ))}
        </div>

        <Paging getPagedUrl={getPagedUrl} page={page} pageSize={pageSize} totalResults={totalResults} />
      </div>
      <nav className="cols-span-1">
        <ProjectFilterList basePath={basePath} projects={projects} />
      </nav>
    </main>
  );
};

export const getBonkDashboard = () => {
  return (
    <div className="text-foreground bg-card ring-stroke hover:ring-stroke/20 group relative block overflow-hidden rounded-lg shadow shadow-black/5 ring-1 transition-colors">
      <a href="https://science.flipsidecrypto.xyz/bonk-burns/" target="_blank" rel="noreferrer">
        <img
          className="aspect-[2/1] w-full bg-white object-cover filter transition-all group-hover:shadow dark:brightness-[85%]"
          src="https://res.cloudinary.com/dsr37ut2z/image/upload/f_auto,q_auto:best,w_500/assets/misc/bonk-burn-tool.jpg"
          alt="Bonk Burn Tool"
          title="Bonk Burn Tool"
        />
        <div className="relative flex h-40 w-full flex-col space-y-1 overflow-hidden p-4">
          <h3>
            <span className="text-contrast pointer-events-none line-clamp-2 block font-medium">Bonk Burn Tool</span>
          </h3>
          <p className="text-muted-foreground line-clamp-3 text-xs">
            <span>May 20, 2024</span> - Tracks the burn rate of $BONK from the various tools and community integrations
            within the Solana ecosystem
          </p>
          <div className="flex-1"></div>
          <div className="align-end flex w-full items-center "></div>
        </div>
      </a>
    </div>
  );
};

const determineSortEvent = (sort: DashboardSearchData["sortBy"]) => {
  switch (sort) {
    case "trending":
      return "filter_dashboard_by_trending";
    case "new":
      return "filter_dashboard_by_newest";
    case "greatest":
      return "filter_dashboard_by_all_time";
    default:
      return "filter_dashboard";
  }
};
